<template>
  <div class="page-zaomeng">
    <top-cover :option="{title:' ',cover: $hwcdnLink('imagehost/vrhome_topcover/zaomeng20230311.jpg')}" />

    <toc :items="tocItems" />

    <main>
      <section v-for="(A,a) in tocItems" :key="a">
        <h2 :id="A.el">{{A.label}}</h2>

        <!-- 内容平台 -->
        <section class="section a" v-if="a===0">
          <template v-for="(B,b) in sectionA.blockA">
            <section class="item" :ref="`section_a__item_${b}`" :class="{ltr: b%2!=0}" :key="b">
              <video class="media" v-if="B.type==='video'" loop muted autoplay :src="B.src"></video>
              <img class="media" v-else-if="B.type==='image'" :src="B.src" :alt="B.intro.title">
              <div class="container">
                <h4 class="title">{{B.intro.title}}</h4>
                <p class="content" v-html="B.intro.content"></p>
              </div>
            </section>

            <div class="empty" :key="`section_a_empty_${b}`">
              <span class="inter-phone" v-if="b===0" v-bind="sectionA.aniA" :key="`section_a_empty_${b}_span`">VR体验店合作咨询——热线电话：18759946956/微信号：zamervr</span>
            </div>
          </template>

          <div class="videos">
            <swiper class="videos-swiper swiper-no-swiping" :options="sectionA.blockB.swiperOptions">
              <swiper-slide class="video-item" v-for="(C,c) in sectionA.blockB.videos" :key="c">
                <video class="media" loop muted :src="C" :ref="`section_a__video_${c}`" @mouseenter="handlePlayVideo(c)" @mouseleave="handlePauseVideo(c)"></video>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>

          <!-- <section class="place">
            <h4>VR电竞馆</h4>
            <h5>已落地地区</h5>
            <p>福建省、内蒙古自治区、广西省、湖南省、陕西省、<br>辽宁省、广东省、黑龙江省、宁夏自治区、西藏自治区等。</p>
            <h5>正在落地地区</h5>
            <p>吉林省、江西省、山东省、浙江省、安徽省、江苏省、<br>四川省、云南省、贵州省、湖北省正在落地当中。</p>

            <div class="btn" @click="$router.push('/contact')">咨询</div>
            <span class="tip">查询您所在城市是否有VR联盟体验店？</span>
          </section> -->
        </section>

        <!-- quest -->
        <section class="section b" v-if="a===1">
          <p class="context a">造梦无线大空间方案是造梦科技自主研发的无线大空间系统，采用了2020年最新发布的Oculus Quest2头显，突破性的解决了VR真实移动和同场交互的行业难题。方案采用最高端的VR头盔、大场地追踪算法、精确动捕系统，为玩家营造最真实的沉浸感和交互体验，为多人玩家提供超大空间的自由移动、对战，真实距离交互。</p>

          <h4>无线大空间内容</h4>
          <div class="games">
            <swiper class="games-swiper swiper-no-swiping" :options="sectionB.swiperOptions">
              <swiper-slide class="games-group" v-for="(A,a) in ['0,4','4,8']" :key="a">
                <div class="container">
                  <template v-for="(B,b) in sectionB.content.slice(A.split(',')[0], A.split(',')[1])">
                    <div class="game" :style="{backgroundImage: `url(${B.cover})`}" :key="b">
                      <div class="title">《{{B.title}}》</div>
                      <div class="context">{{B.context}}</div>
                    </div>
                  </template>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>

          <h4>专业打造VR体验馆</h4>
          <div class="tags-box">
            <ul class="tags">
              <li class="tag" :class="{active: a===sectionB.tags.current}" v-for="(A,a) in sectionB.tags.list" :key="a" @mouseover="sectionB.tags.current=a">{{A.label}}</li>
            </ul>
            <p class="context">{{sectionB.tags.list[sectionB.tags.current].context}}</p>
          </div>
          <div class="space">
            <div class="item" v-for="(A,a) in sectionB.space" :key="$randomKey(a)">
              <div class="top">
                <h3>{{A.title}}</h3>
                <span class="subtitle">{{A.subtile}}</span>
                <p class="intro" v-html="A.intro"></p>
              </div>
              <div class="tags">
                <span class="title">包含</span>
                <ul class="list">
                  <li class="item" v-for="(B,b) in A.tags" :key="$randomKey(b)">
                    <span>{{B.label}}</span>
                    <i class="iconfont icon-gou3" v-if="B.type=='icon'"></i>
                    <span v-else>{{B.type}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <p class="tip">了解更多关于Oculus Quest2以及无线大空间方案，享受真正无束缚体验，欢迎您随时联系</p>
          <div class="btn" @click="$router.push('/contact')">了解更多</div>
        </section>
      </section>
    </main>

    <partner />
    <page-footer class="footer" />
  </div>
</template>

<script>
import topCover from "@/components/TopCover";
import pageFooter from "@/components/Footer";
import partner from "@/components/Partner";
import toc from "@/components/Toc";

import skrollr from "skrollr";

export default {
  components: {
    topCover,
    pageFooter,
    partner,
    toc,
  },
  data() {
    return {
      tocItems: [
        {
          icon: "icon-pingtai",
          label: "造梦内容平台",
          el: "toc_content",
        },
        {
          icon: "icon-fangan1",
          label: "Quest无线大空间方案",
          el: "toc_quest",
        },
      ],

      sectionA: {
        blockA: [
          {
            type: "video",
            src: this.$hwcdnLink("videohost/vrhome_zaomeng/zaomengvr.mp4"),
            intro: {
              title: "造梦VR是什么？",
              content:
                "造梦VR是国内最大的VR内容平台，线下VR体验馆经营必备平台，多人联机更加沉浸式、交互性强、盈利方面比单人体验更高。我们宗旨在为用户提供最优质的游戏内容和服务体验，让每一位玩家都可以在造梦找到喜爱的内容，引领线下VR变革！",
            },
          },
          /* {
            type: 'image',
            src: require('@/assets/screen_2_cover.gif'),
            intro: {
              title: '超级空间（真人竞技）',
              content: '充分发挥店面价值<br>超级空间覆盖的100平范围内，<br>告别基站干扰，无需任何隔断，<br>轻松实现超级空间、竞技空间、动感空间的灵活搭配，<br>大大提升店面空间利用率'
            }
          }, */
          {
            type: "image",
            src: require("@/assets/zaomengvr.png"),
            intro: {
              title: "造梦VR内容平台有什么？",
              content:
                "1000+精品VR内容<br>造梦VR已上线千款精品VR内容，游戏类型丰富多样，休闲类，枪战类，密室类，动作类，探险类，教育类，观影类等等，满足玩家多样需求！<br><br>热门大作永久更新<br>国内外热门大作实时更新，精品VR游戏每周上新！<br><br>24h售后技术支持<br>7*24h售后在线技术支持，安装使用全程无忧！<br><br>专业门店管理系统<br>专为体验馆打造门店管理系统，计时，计费，会员，收银等等功能一应俱全，助力体验馆轻松管理！<br><br>精彩活动持续不断<br>定期举办全国性大型赛事，精彩游戏活动不间断。",
            },
          },
        ],
        blockB: {
          swiperOptions: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 20,
            cssMode: true,
            mousewheel: false,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          },
          videos: [
            this.$hwcdnLink(
              "videohost/vrhome_zaomeng/Focus_on_you—恋爱日记.mp4"
            ),
            this.$hwcdnLink("videohost/vrhome_zaomeng/VR机器人.mp4"),
            this.$hwcdnLink("videohost/vrhome_zaomeng/赤壁大战联机版.mp4"),
            this.$hwcdnLink("videohost/vrhome_zaomeng/功夫大师.mp4"),
            this.$hwcdnLink("videohost/vrhome_zaomeng/街头跑酷.mp4"),
            this.$hwcdnLink("videohost/vrhome_zaomeng/劲舞天地—VR跳舞机.mp4"),
            this.$hwcdnLink("videohost/vrhome_zaomeng/哨兵·黎明方尖.mp4"),
            this.$hwcdnLink("videohost/vrhome_zaomeng/童话王国射击VR.mp4"),
          ],
        },
        aniA: {}, // 中间插入电话的动画参数
      },
      sectionB: {
        swiperOptions: {
          spaceBetween: 20,
          cssMode: true,
          mousewheel: false,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        },
        content: [
          {
            cover: this.$hwcdnLink("imagehost/vrhome_zaomeng_space/game_1.png"),
            title: "义庄派对大空间",
            context:
              "《义庄派对大空间》是一款恐怖刺激的VR游戏。具有中国特色恐怖元素，以千年僵尸和茅山道长为人物原型，常年占据中国VR恐怖游戏榜前5，累计体验超过200W人次的《义庄派对》，终于迎来了4人版本！童子尿，铜钱镖，灭尸符，定身符，四人征战，所向披靡！新角色，新剧情，新方式，带来全新感受！",
          },
          {
            cover: this.$hwcdnLink("imagehost/vrhome_zaomeng_space/game_2.png"),
            title: "梦境：起源",
            context:
              "一艘来自遥远破败星球的飞船，降临到了这颗他们命名为“Dreamland”的新星球。但Dreamland作为一颗魔法星球，遍布着战斗力不弱于那些入侵者的强大魔法师。入侵者们在殖民的初期，就遭遇了魔法师们激烈的抵抗...",
          },
          {
            cover: this.$hwcdnLink("imagehost/vrhome_zaomeng_space/game_3.png"),
            title: "太阳神",
            context:
              "多年前, 一个未知的星球被地球发出的人工智能“Helios”发现并占领。你和你的队友组成人类探勘队，必须消灭变质的“Helios”, 并在星球主塔设置核子弹。将核子弹藏入主塔并激活核子弹能源盾以吸引“Helios”进入星球主塔。 在Helios进入星球主塔后, 人类探勘队必须在核子弹引爆前登上逃脱船成功远离星球。",
          },
          {
            cover: this.$hwcdnLink("imagehost/vrhome_zaomeng_space/game_4.png"),
            title: "鬼屋惊魂",
            context:
              "《鬼屋惊魂》：是一款恐怖刺激的探险类VR游戏。离奇死亡的一家三口、诡异的实验室、阴森的焚尸炉、古老的提灯，面具下隐藏的是什么？让我们一起去探险揭开这“疯狂派对”的神秘真相吧！",
          },
          {
            cover: this.$hwcdnLink("imagehost/vrhome_zaomeng_space/game_5.png"),
            title: "糖果奇兵",
            context:
              "《糖果奇兵》这个游戏是一款射击类VR游戏。游戏画面亮丽、场景丰富。游戏的主人公是一对圆乎乎的胖兔子。可爱单纯的兔子们化身为“伟大的守卫者”来保卫糖果镇。面对来势汹汹的坏蛋们，守卫者要用自己的武器‘雪球’，把的敌人消灭掉快来一起守卫糖果镇，成为真正的糖果英雄吧！",
          },
          {
            cover: this.$hwcdnLink("imagehost/vrhome_zaomeng_space/game_6.png"),
            title: "尸人派对",
            context:
              "《尸人派对》是一款恐怖刺激的探险类VR游戏。一帮热情澎湃的年轻人，为给老铁一次惊险刺激的生日party，精心准备了一个游戏。离奇死亡的一家三口、诡异的实验室、阴森的焚尸炉、古老的提灯，面具下隐藏的是什么？是血腥的杀戮？还是故作恐怖？ 这究竟是个恶作剧还是真的遇见了鬼？结局究竟怎样？让我们一起去探险揭开这“疯狂派对”的神秘真相吧！",
          },
          {
            cover: this.$hwcdnLink("imagehost/vrhome_zaomeng_space/game_7.png"),
            title: "龙焱特攻：利刃出鞘",
            context:
              "《龙焱特攻：利刃出鞘》没有什么比真正的战场更能体现英雄的荣耀，也没有什么比激烈甚至惨烈的战争更能激发一个人的斗志。英雄们即将手持利刃披挂出征，在枪林弹雨的突袭下捍卫“龙焱特攻”的荣耀。子弹已上膛，利刃已出鞘，朋友该你们上场了！！！",
          },
          {
            cover: this.$hwcdnLink("imagehost/vrhome_zaomeng_space/game_8.png"),
            title: "星际舰队-入侵",
            context:
              "《星际舰队-入侵》是一款伴随同名连载小说改编的星际探险类大空间多人互动游戏。现实与虚拟 1:1 同步，游戏可适配 50-100 平米的场馆，适用所有年龄段的玩家。",
          },
        ],
        tags: {
          list: [
            {
              label: "超真实",
              context:
                "大空间多人VR互动最大的优势在于可以在虚拟场景中真实行走，大幅提升VR体验馆的可玩性",
            },
            {
              label: "零眩晕",
              context:
                "大空间解决了传统VR用手柄移动导致眩晕的问题，真实行走真实交互",
            },
            {
              label: "无束缚",
              context:
                "大空间摆脱了原有VR体验的线缆束缚，玩家可以无拘无束的在虚拟世界中自由的交互",
            },
            {
              label: "高门槛",
              context:
                "大空间对于场地的要求高，玩家们只有在线下店才能体验到，为体验馆长期可持续发展提供保障",
            },
            {
              label: "最低价",
              context:
                "造梦以最大的诚意，最低的成本价对外推广，为体验馆升级Quest大空间提供最好的机会",
            },
          ],
          current: 0,
        },
        space: [
          {
            title: "迷你公寓店",
            subtile: "(60平米)",
            intro: "投入低<br>坪效高<br>空间利用率高<br>回本周期短",
            tags: [
              { label: "设备", type: "icon" },
              { label: "基站", type: "icon" },
              { label: "吧台", type: "icon" },
              { label: "绿幕区", type: "icon" },
              { label: "休闲区", type: "icon" },
              { label: "游戏区域（双人包间）", type: "icon" },
            ],
          },
          {
            title: "标准大空间公寓店",
            subtile: "(100平米)",
            intro:
              "大空间带来产品差异化<br>同行业提升竞争力<br>休息区优化方案<br>可减少等待顾客的流失率",
            tags: [
              { label: "设备", type: "icon" },
              { label: "基站", type: "icon" },
              { label: "吧台", type: "icon" },
              { label: "绿幕区", type: "icon" },
              { label: "休闲区", type: "升级版" },
              { label: "游戏区域（双人包间）", type: "icon" },
              { label: "Quest无线大空间", type: "icon" },
            ],
          },
          {
            title: "综合型大空间公寓店",
            subtile: "(130平米)",
            intro:
              "综合型娱乐空间<br>除正常到店顾客外<br>还可以预约接待团建<br>聚会、包场、生日派对等<br>在淡季提升收入",
            tags: [
              { label: "设备", type: "icon" },
              { label: "基站", type: "icon" },
              { label: "吧台", type: "icon" },
              { label: "绿幕区", type: "icon" },
              { label: "休闲区", type: "定制版" },
              { label: "游戏区域（双人包间）", type: "icon" },
              { label: "Quest无线大空间", type: "icon" },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.initAni().then(() => {
      skrollr.init();
    });

    setTimeout(() => {
      console.log(this.$refs);
    }, 2000);
  },
  beforeDestroy() {
    skrollr.init().destroy();
  },
  methods: {
    initAni() {
      return new Promise((R) => {
        // 电话
        const el1 = this.$refs.section_a__item_1[0],
          doc = document.documentElement;

        const scope = [el1.offsetTop - doc.clientHeight, el1.offsetTop];

        let _aniA = {};
        _aniA[`data-${scope[0]}`] = "transform:translateX(300%)";
        _aniA[`data-${scope[0] + 50 + 100}`] = "transform:translateX(-50%)";
        _aniA[`data-${scope[1] - 220 - 100}`] = "transform:translateX(-50%)";
        _aniA[`data-${scope[1]}`] = "transform:translateX(-400%)";
        this.$set(this.sectionA, "aniA", _aniA);

        R();
      });
    },

    /** 播放暂停视频 */
    handlePlayVideo(index) {
      this.$refs[`section_a__video_${index}`][0].play();
    },
    handlePauseVideo(index) {
      this.$refs[`section_a__video_${index}`][0].pause();
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  width: 1080px;
  margin: 0 auto;
  padding-top: 24px;

  h2 {
    text-align: center;
    line-height: 1;
    margin: 0;
    font-size: 30px;
    color: #1c1e20;
    font-weight: lighter;
    padding-top: 120px;
    margin-bottom: 100px;
  }
}

.section {
  // box-sizing: border-box;
}

.section.a {
  .item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &.ltr {
      flex-direction: row-reverse;
    }

    .media {
      width: 500px;
      height: auto;
    }
    .container {
      width: 500px;

      .title {
        font-size: 24px;
        color: #1c1e20;
        line-height: 1;
        margin: 20px 0 30px;
        font-weight: normal;
      }
      .content {
        font-size: 18px;
        color: rgba(#323436, 0.8);
        line-height: 46px;
      }
    }
  }

  .empty {
    width: 100%;
    height: 120px;
    overflow-x: hidden;
    position: relative;

    .inter-phone {
      text-align: center;
      font-size: 18px;
      line-height: 1;
      position: absolute;
      display: block;
      margin-top: 51px;
      white-space: nowrap;
      left: 50%;
      transform: translateX(300%);
    }
  }

  .videos {
    width: 100%;
    height: 144px;
    position: relative;

    .videos-swiper {
      width: 100%;
      height: 100%;

      .video-item {
        position: relative;

        &::before,
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
        }
        &::before {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          border: solid 2px #fff;
          transform: translate(-50%, -50%);
        }
        &::after {
          border-left: solid 15px #fff;
          border-top: solid 10px transparent;
          border-bottom: solid 10px transparent;
          transform: translate(calc(-50% + 2px), -50%);
          border-radius: 3px;
        }

        &:hover {
          &::before,
          &::after {
            display: none;
          }
        }
      }

      .media {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      width: 50px;
      height: 144px;
      position: absolute;
      top: 0;
      margin: 0;

      &::after {
        font-size: 30px;
        color: rgba(#1c1e20, 0.3);
      }
    }
    .swiper-button-prev {
      left: 0;
      transform: translateX(-100%);
    }
    .swiper-button-next {
      right: 0;
      transform: translateX(100%);
    }
  }

  .place {
    line-height: 1;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      font-size: 24px;
      color: #1c1e20;
      margin-bottom: 60px;
      font-weight: lighter;
    }
    h5 {
      font-size: 18px;
      font-weight: lighter;
      color: rgba(#323436, 0.8);
    }
    p {
      font-size: 14px;
      color: rgba(#323436, 0.8);
      line-height: 46px;
      letter-spacing: 4px;
      padding: 0;
      margin: 0;
      text-align: center;
    }
    .btn {
      width: 200px;
      height: 46px;
      box-sizing: border-box;
      border: 1px solid #0edee3;
      line-height: 46px;
      text-align: center;
      color: #0edee3;
      margin-top: 60px;
      display: inline-block;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;
      &:hover {
        background-color: #0edee3;
        color: #fff;
      }
    }
    .tip {
      font-size: 14px;
      color: rgba(#323436, 0.5);
      margin-top: 20px;
    }
  }
}

.section.b {
  .context.a {
    font-size: 18px;
    line-height: 49px;
    color: rgba(#323436, 0.8);
    text-align: center;
    margin: 0;
    padding: 0;
  }

  h4 {
    text-align: center;
    font-size: 24px;
    color: #1c1e20;
    font-weight: lighter;
  }

  .games {
    width: 100%;
    height: 244px;
    outline: solid 1px #ccc;
    position: relative;

    .games-group {
      height: 100%;
    }

    .container {
      display: flex;
      height: 244px;
      color: #fff;

      .game {
        flex: 1;
        height: 100%;
        position: relative;
        background-size: cover;
        background-position: center;
        transition: flex 0.3s;
        cursor: default;

        .title {
          width: 100%;
          padding: 30px 0 15px;
          line-height: 1;
          font-size: 18px;
          text-align: center;
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          background-image: linear-gradient(to bottom, transparent, #000);
          transform: translateY(0);
          transition: transform 0.3s;
        }
        .context {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(#000, 0.5);
          padding: 30px;
          box-sizing: border-box;
          font-size: 14px;
          line-height: 1.5;
          opacity: 0;
          transition: opacity 0.6s;
        }

        &:hover {
          flex: 1.5;

          .title {
            transform: translateY(100%);
          }
          .context {
            opacity: 1;
          }
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      width: 30px;
      height: 30px;
      position: absolute;
      left: unset;
      top: unset;
      right: -40px;
      bottom: 0;

      &::after {
        font-size: 20px;
        color: #1c1e20;
      }
    }
    .swiper-button-next {
      bottom: 40px;
    }
  }

  .tags-box {
    .tags {
      padding: 0 200px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // border-bottom: solid 1px rgba(#323436, .1);
      position: relative;
      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: rgba(#323436, 0.1);
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
      }
      .tag {
        padding: 16px 10px;
        list-style: none;
        position: relative;
        font-size: 18px;
        color: #323436;
        overflow: hidden;
        cursor: default;
        &.active {
          color: #0edee3;
          &::before {
            content: "";
            width: 100%;
            height: 1px;
            background-color: #0edee3;
            position: absolute;
            left: 0;
            bottom: 0px;
          }
          &::after {
            content: "";
            width: 10px;
            height: 10px;
            background-color: #fff;
            border: solid 1px #0edee3;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 50%) rotate(45deg);
          }
        }
      }
    }
    .context {
      text-align: center;
      font-size: 18px;
      color: #323436;
      line-height: 1;
      padding: 40px 0;
      margin: 0;
    }
  }

  .space {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    margin-top: 40px;

    & > .item {
      background-color: #fff;
      box-shadow: 8px 8px 20px 0px rgba(51, 51, 51, 0.15);
      .top {
        height: 460px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
        h3 {
          font-size: 24px;
          color: #181a1b;
          margin-bottom: 24px;
          line-height: 1;
          font-weight: lighter;
        }
        .subtitle {
          font-size: 14px;
          color: #323436;
          margin-bottom: 90px;
          line-height: 1;
        }
        .intro {
          font-size: 18px;
          color: #323436;
          line-height: 46px;
          text-align: center;
          padding: 0;
          margin: 0;
        }
      }
      .tags {
        padding: 40px;
        box-sizing: border-box;
        .title {
          font-size: 18px;
          color: rgba(#323436, 0.5);
          line-height: 1;
          display: block;
          margin-bottom: 30px;
        }
        .list {
          padding: 0;
          margin: 0;
          .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #323436;
            &:not(:last-child) {
              margin-bottom: 30px;
            }
            .iconfont {
            }
          }
        }
      }

      &:nth-child(1) {
        .top {
          background-color: rgba(#6190e8, 0.5);
        }
      }
      &:nth-child(2) {
        .top {
          background-color: rgba(#ff7e5f, 0.5);
        }
      }
      &:nth-child(3) {
        .top {
          background-color: rgba(#0edee3, 0.5);
        }
      }
    }
  }

  .tip {
    font-size: 14px;
    color: #323436;
    text-align: center;
    padding: 0;
    margin: 120px 0 40px;
  }
  .btn {
    width: 200px;
    height: 46px;
    background-color: #0edee3;
    font-size: 18px;
    color: #fff;
    text-align: center;
    line-height: 46px;
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>
